import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { MarketDoc, SelectedMarketData } from 'data';
import { currencyShortDisplay } from 'utils';
import { StyledGutter } from 'components/Gutter';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { appActions } from 'store/app';
import { getDepositContract } from 'utils/depositContractHelpers';
import { formatUnits } from 'ethers/lib/utils';
import { RootState } from 'store';
import { useEthersSigner } from 'hooks/useEtherSigner';
import { useAccount } from 'wagmi';
import { getMarketInsight } from 'apis';

export const Market = () => {
	const [selectedMarket, setSelectedMarket] = useState<SelectedMarketData>();
	const [contractBal, setContractBal] = useState('0');
	const { id } = useParams<{ id: string }>();
	const history = useHistory();
	const dispatch = useDispatch();
	const location: { state: { market: MarketDoc } } = useLocation();
	const { address: account } = useAccount();
	const selectedVault = useSelector((state: RootState) => state.vault);
	const selectedAsset = selectedVault.token.find((d) => d.name === 'USDC');
	const selectedExchange = selectedVault.exchanger.find(
		(d) => d.value === selectedVault.exchange,
	);
	const signer: any = useEthersSigner();
	const isVisible = contractBal && parseFloat(contractBal) > 0;
	const marketData =
		(location && location.state && location.state.market) || {};

	const fetchMarket = useCallback(async () => {
		try {
			const res = await getMarketInsight(id, account || '');
			setSelectedMarket(res);
		} catch (e) {
			//
		}
	}, [id, account]);

	const fetchContractBalance = useCallback(async () => {
		try {
			if (account && selectedExchange && selectedAsset && signer) {
				dispatch(
					appActions.setLoading({
						loading: true,
						message: 'Please Wait...',
					}),
				);
				const contract = getDepositContract(
					selectedExchange.contractAddress || '',
					signer.provider.getSigner(account).connectUnchecked(),
				);

				const userContractBalance = await contract.balanceOf(account);
				const userContractBal = await contract.convertToAssets(
					userContractBalance,
				);
				setContractBal(
					formatUnits(userContractBal, selectedAsset.decimalValue) ||
						'0',
				);
			} else {
				setContractBal('0');
			}
		} catch {
			// handle error silently
		} finally {
			dispatch(appActions.setLoading({ loading: false, message: '' }));
		}
	}, [account, dispatch, selectedExchange, signer, selectedAsset]);

	useEffect(() => {
		dispatch(
			appActions.setLoading({
				loading: false,
				message: 'Please Wait...',
			}),
		);
	}, [dispatch]);

	useEffect(() => {
		fetchContractBalance();
	}, [fetchContractBalance]);

	useEffect(() => {
		fetchMarket();
	}, [fetchMarket]);

	const renderTextInsights = useMemo(() => {
		if (
			!selectedMarket ||
			!selectedMarket.insights ||
			!selectedMarket.insights.length
		) {
			return null;
		}
		const hasTitle = !!selectedMarket.insights[0].title;

		return (
			<div>
				<h3 style={{ fontWeight: 900 }}>Insights</h3>
				{hasTitle ? (
					<div>
						{selectedMarket.insights.map((insight) => (
							<div key={insight.title}>
								<div className="bold">{insight.title}</div>
								<StyledGutter height={8} />
								<div>{insight.description}</div>
								<StyledGutter height={16} />
							</div>
						))}
					</div>
				) : (
					<ul className="reduced-margin-list">
						{selectedMarket.insights.map((insight) => (
							<li key={insight.description}>
								{insight.description}
							</li>
						))}
					</ul>
				)}
			</div>
		);
	}, [selectedMarket]);

	return (
		<div className="container">
			<Link
				to={'/?page=insights'}
				className="back-button"
				href={'/?page=insights'}
			>
				Back
			</Link>

			<StyledGutter height={32} />

			<div className="market-insights-grid">
				<div>
					<div className="card">
						{/* Active working area start */}
						<div className="market-info-grid">
							<img
								className="market-icon-large icon"
								src={marketData?.icon}
							/>
							<div className="market-title-large title">
								{marketData?.title}
							</div>
							<div className="metrics flex-row space-between">
								<div className={'column-start'}>
									<div className="bold description-text">
										End Date
									</div>
									<div>
										{moment(marketData?.endDate).format(
											'MMM D, YYYY',
										)}
									</div>
								</div>
								<div className={'column-start'}>
									<div className="bold description-text">
										Volume
									</div>
									<div>
										{currencyShortDisplay(
											marketData?.volume || 0,
										)}
									</div>
								</div>
								<div className={'column-start'}>
									<div className="bold description-text">
										Volume (24H)
									</div>
									<div>
										{currencyShortDisplay(
											marketData?.volume24hr || 0,
										)}
									</div>
								</div>
								<div className={'column-start'}>
									<div className="bold description-text">
										Liquidity
									</div>
									<div>
										{currencyShortDisplay(
											marketData?.liquidity || 0,
										)}
									</div>
								</div>
							</div>
						</div>
						{/* Active working area end */}
						<StyledGutter height={16} />
						<div className={'column-start'}>
							<h3 style={{ fontWeight: 800 }}>Description</h3>
							<div className="description-text">
								{marketData?.description}
							</div>
						</div>
						{isVisible && (
							<div className={'column-start'}>
								{selectedMarket &&
									selectedMarket.probabilities &&
									!!selectedMarket.probabilities.length && (
										<>
											<h3 style={{ fontWeight: 800 }}>
												Probability distribution
											</h3>
											<div className="flex-column">
												{selectedMarket.probabilities.map(
													(prob) => (
														<div
															className="flex-row"
															key={prob.outcome}
														>
															<>{prob.outcome}:</>
															<StyledGutter
																width={8}
															/>
															<div
																style={{
																	marginBottom: 8,
																}}
															>
																{typeof prob.probability ==
																'number' ? (
																	prob.probability
																) : (
																	<div className="bold">
																		{
																			prob.probability
																		}
																	</div>
																)}
																{typeof prob.probability ==
																	'number' &&
																	'%'}
															</div>
														</div>
													),
												)}
											</div>
										</>
									)}
							</div>
						)}
					</div>
				</div>
				<div>
					{isVisible ? (
						renderTextInsights
					) : (
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
								justifyContent: 'center',
								flex: 1,
							}}
						>
							<h3
								style={{
									fontWeight: 800,
									fontSize: 25,
									marginBottom: 0,
								}}
							>
								Access Required
							</h3>
							<p
								style={{
									color: '#999',
									fontSize: 18,
									fontWeight: 500,
								}}
							>
								{account
									? `You need to deposit into the vault to access insights`
									: `You need to connect wallet and deposit into the vault to access insights`}
							</p>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};
