import React, { useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Box, Tab, Tabs as MuiTabs, Typography } from '@mui/material';
import { DepositPanel } from 'pages/NewDashboard/components/DepositPanel';
import { WithdrawPanel } from 'pages/NewDashboard/components/Withdraw';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { Vault, VAULTS } from 'data/Vaults';
import { ItemCard } from 'pages/Landing/components/ItemCard';
import { PnlChartComponent } from 'pages/Landing/components/TvlGraph';
import axios from 'axios';
import { Header } from 'pages/Landing/components/Header';
import { appActions } from 'store/app';
import { getDepositContract } from 'utils/depositContractHelpers';
import { formatUnits } from 'ethers/lib/utils';
import { useEthersSigner } from 'hooks/useEtherSigner';
import { useAccount } from 'wagmi';
import { Markets } from 'pages/Markets';
import { useHistory, useLocation } from 'react-router-dom';

const Container = styled('div')`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding-top: 40px;
`;

const Wrapper = styled('div')`
	display: grid;
	background-color: white;
	grid-template-columns: 1fr 1fr 1.5fr;
	align-items: center;
	justify-items: center;
	width: fit-content;
	height: 100%;
	padding: 40px 0 0 0;

	@media (max-width: 1100px) {
		grid-template-columns: 1fr;
	}
`;

const Column = styled('div')`
	display: flex;
	flex-direction: column;
`;

const StyledRow = styled('div')`
	display: flex;
	flex: 1 1;
	flex-direction: row;
	flex-wrap: nowrap;
	width: 100%;
	justify-content: center;
	align-items: center;
	gap: 0;
`;

const Row = styled('div')`
	display: flex;
	flex-direction: row;
`;

const Text = styled(Typography, {
	shouldForwardProp: (prop) => prop !== 'isActive',
})<{ isActive: boolean; disabled?: boolean }>`
	display: flex;
	align-items: center;
	justify-content: center;
	color: ${({ isActive }) => (isActive ? '#b9886d' : '#b3b3bb')};
	margin-right: 20px;
	font-weight: 600;
	opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};

	:hover {
		cursor: ${({ disabled }) => (disabled ? 'no-drop' : 'pointer')};
		color: ${({ disabled }) => (disabled ? 'none' : '#bd937c')};
	}
`;

const Withdraw = styled('div')`
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 500px;
	height: 100%;
	padding: 0 10px 40px 10px;
`;

const BorderBox = styled(Withdraw)`
	height: 100%;
`;

const Title = styled('div')(() => ({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	justifyContent: 'start',
	fontSize: 36,
	fontWeight: 'bold',
	marginBottom: 20,
}));

const StyledBox = styled('div')(() => ({
	display: 'inline',
	padding: '6px 13px 12px 13px',
	justifyContent: 'center',
	alignItems: 'center',
	backgroundColor: '#b9886d',
	borderRadius: 8,
	width: 'min-content',
	height: 'min-content',
}));

const Flex = styled('div')``;

enum Tabs {
	Deposit = 'Deposit',
	WithDraw = 'WithDraw',
}

function a11yProps(index: number) {
	return {
		id: `custom-tab-${index}`,
		'aria-controls': `custom-tabpanel-${index}`,
	};
}

const StyledTabs = styled(MuiTabs)({
	border: '1px solid #fff',
	borderRadius: '50px',
	overflow: 'hidden',
	minHeight: 30,
	'& .MuiTabs-indicator': {
		display: 'none',
	},
});

const Center = styled(Box)`
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: center;
`;

const StyledTab = styled(Tab)({
	textTransform: 'none',
	fontWeight: 700,
	borderRight: '1px solid #e0e0e0',
	borderRadius: '0',
	padding: '3px 16px',
	minHeight: 40,
	'&.Mui-selected': {
		backgroundColor: '#B9886D',
		color: '#ffffff',
	},
	'&:last-of-type': {
		borderRight: 'none',
	},
});

export const NewDashboard = () => {
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const page = queryParams.get('page');
	const [pageTab, setPageTab] = useState(page === 'insights' ? 1 : 0);
	const [contractBal, setContractBal] = useState('0');
	const [stat, setStat] = useState({
		interest_value: 0,
		pnl: '',
		portfolio_value: '',
		volume_trade: '',
	});
	const [activeTab, setActiveTab] = useState<Tabs>(Tabs.Deposit);
	const selectedVault = useSelector((state: RootState) => state.vault);
	const { address: account } = useAccount();
	const dispatch = useDispatch();
	const appState = useSelector((state: RootState) => state.app);
	const selectedAsset = selectedVault.token.find((d) => d.name === 'USDC');
	const selectedExchange = selectedVault.exchanger.find(
		(d) => d.value === selectedVault.exchange,
	);
	const signer: any = useEthersSigner();
	const history = useHistory();

	const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
		setPageTab(newValue);
		// Update the URL based on the selected tab index using history.push
		if (newValue === 0) {
			history.push('?page=vault');
		} else if (newValue === 1) {
			history.push('?page=insights');
		}
	};

	const fetchContractBalance = useCallback(async () => {
		try {
			if (account && selectedExchange && selectedAsset && signer) {
				dispatch(
					appActions.setLoading({
						loading: true,
						message: 'Please Wait...',
					}),
				);
				const contract = getDepositContract(
					selectedExchange.contractAddress || '',
					signer.provider.getSigner(account).connectUnchecked(),
				);

				// fetch user's contract balance
				const userContractBalance = await contract.balanceOf(account);
				const userContractBal = await contract.convertToAssets(
					userContractBalance,
				);
				setContractBal(
					formatUnits(userContractBal, selectedAsset.decimalValue) ||
						'0',
				);
			} else {
				setContractBal('0');
			}
		} catch {
			// handle error silently
		} finally {
			dispatch(appActions.setLoading({ loading: false, message: '' }));
		}
	}, [account, dispatch, selectedExchange, signer, selectedAsset]);

	const fetchStat = useCallback(async () => {
		try {
			const { data } = await axios.get(
				'https://stats-apis.rndm.io/api/get-stats',
			);
			setStat(data);
		} catch (e) {
			//
		}
	}, []);

	useEffect(() => {
		fetchStat();
	}, [fetchStat]);

	useEffect(() => {
		fetchContractBalance();
	}, [fetchContractBalance]);

	return (
		<>
			<Container>
				<Flex>
					<Center>
						<Box
							sx={{
								display: 'flex',
								width: 'fit-content',
							}}
						>
							<StyledTabs
								value={pageTab}
								onChange={handleTabChange}
								variant="fullWidth"
								textColor="inherit"
								aria-label="Styled Button Group-like Tabs"
							>
								<StyledTab label="Vault" {...a11yProps(0)} />
								<StyledTab label="Insights" {...a11yProps(1)} />
							</StyledTabs>
						</Box>
					</Center>

					{pageTab === 0 && (
						<>
							<Header stat={stat} contractBal={contractBal} />
							<Wrapper>
								<BorderBox style={{ paddingLeft: 20 }}>
									<Title>
										<StyledBox>
											<Typography
												variant="h5"
												color="#fff"
												fontWeight={800}
												display={'inline'}
											>
												ai
											</Typography>
										</StyledBox>
										<Box width={16} />
										<Typography
											variant="h4"
											fontWeight={800}
											display={'inline'}
										>
											agent {selectedVault.name}
										</Typography>
									</Title>
									<Row>
										<Text
											isActive={
												activeTab === Tabs.Deposit
											}
											onClick={() =>
												setActiveTab(Tabs.Deposit)
											}
										>
											Deposit
										</Text>
										<Text
											isActive={
												activeTab === Tabs.WithDraw
											}
											disabled={
												parseFloat(contractBal) === 0
											}
											onClick={() => {
												if (
													parseFloat(contractBal) !==
													0
												) {
													setActiveTab(Tabs.WithDraw);
												}
											}}
										>
											Claim
										</Text>
									</Row>
									<Box height={16} />
									{activeTab === Tabs.Deposit && (
										<DepositPanel
											onSuccess={() => {
												fetchContractBalance();
											}}
										/>
									)}
									{activeTab === Tabs.WithDraw && (
										<WithdrawPanel
											onSuccess={() => {
												fetchContractBalance();
												setActiveTab(Tabs.Deposit);
											}}
										/>
									)}
								</BorderBox>

								<BorderBox>
									<Column>
										<StyledRow>
											{VAULTS[
												appState.selectedNetwork
											].map((vault: Vault) => (
												<ItemCard
													key={vault.name}
													stat={stat}
													{...vault}
												/>
											))}
										</StyledRow>
									</Column>
								</BorderBox>

								<Withdraw style={{ paddingRight: 20 }}>
									<Column>
										<PnlChartComponent />
									</Column>
								</Withdraw>
							</Wrapper>
						</>
					)}
				</Flex>
			</Container>
			{pageTab === 1 && <Markets />}
		</>
	);
};
