import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { MarketDoc } from 'data';
import { currencyShortDisplay } from 'utils';
import { appActions } from 'store/app';
import { useDispatch } from 'react-redux';
import { Gutter, StyledGutter } from 'components/Gutter';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { getMarketList } from 'apis';

export const Markets = () => {
	const [markets, setMarkets] = useState<MarketDoc[]>([]);
	const dispatch = useDispatch();

	const fetchMarketList = useCallback(async () => {
		try {
			const res = await getMarketList();

			setMarkets(res);
		} catch (e) {}
	}, []);

	useEffect(() => {
		dispatch(
			appActions.setLoading({
				loading: false,
				message: 'Please Wait...',
			}),
		);
	}, [dispatch]);

	useEffect(() => {
		fetchMarketList();
	}, [fetchMarketList]);

	const marketsView = useMemo(() => {
		return markets.map((market) => (
			<Link
				href={`/market/${market.id}`}
				to={{ pathname: `/market/${market.id}`, state: { market } }}
				className="market-card"
				key={market.id}
			>
				<div className="flex-row">
					<img className="market-icon" src={market.icon} alt="" />
					<StyledGutter width={18} />
					<span className="market-title">{market.title}</span>
				</div>
				<StyledGutter height={16} />
				<div className="flex-row">
					<span className="market-volume">
						{currencyShortDisplay(market.volume)} Volume
					</span>
				</div>
			</Link>
		));
	}, [markets]);

	return (
		<div style={{ padding: 20 }}>
			<div className="flex-row markets-controls">
				<Typography variant="h4">Markets</Typography>
				<Gutter spacing={5} />
			</div>
			<div className="markets-grid">{marketsView}</div>
		</div>
	);
};
