import moment from 'moment';

export const hasOneHourPassed = (startTime: string) => {
	const currentTime = moment();
	const startTimeMoment = moment(startTime);

	const differenceInHours = currentTime.diff(startTimeMoment, 'hours');

	return differenceInHours < 1;
};

export const getLockMessage = (canWithdraw?: boolean) => {
	const forcedUnlockMoment = moment(
		'2025-01-06 00:00:00',
		'YYYY-MM-DD HH:mm:ss',
	);
	const now = moment();

	if (now.isBefore(forcedUnlockMoment)) {
		return `Claim at ${forcedUnlockMoment.format('DD-MMM, hh:mm A')}`;
	}

	return canWithdraw ? 'Withdraw' : 'Claim';
};

export const checkLock = (canWithdraw?: boolean) => {
	return getLockMessage(canWithdraw);
};

export const adjustDate = (isoDateString: string) => {
	const date = moment(isoDateString);
	const dayOfMonth = date.date();

	if (dayOfMonth <= 14) {
		date.add(31, 'days');
	} else {
		date.add(61, 'days').startOf('month');
	}
	const dayOfWeek = date.day();
	if (dayOfWeek === 6) {
		date.add(2, 'days');
	} else if (dayOfWeek === 0) {
		date.add(1, 'days');
	}

	date.set({ hour: 15, minute: 1, second: 0, millisecond: 0 });

	return date.toISOString();
};
