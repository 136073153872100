import React from 'react';
import {
	Box,
	Button,
	IconButton,
	Modal,
	Tooltip,
	Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/system';
import InfoIcon from '@mui/icons-material/Info';
import { formatNumber } from 'utils/formatNumber';

const Row = styled('div')`
	display: flex;
	flex-direction: column;
`;

export const WithdrawModal = ({
	open,
	onClose,
	info,
}: {
	open: boolean;
	onClose(success?: boolean): void;
	info: { amount: string; earned: number };
}) => {
	const handleClose = () => onClose();

	return (
		<Modal
			open={open}
			onClose={(event, reason) => {
				if (reason === 'backdropClick') return;
				handleClose();
			}}
			aria-labelledby="modal-title"
		>
			<Box
				sx={{
					position: 'absolute',
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)',
					width: 400,
					bgcolor: 'background.paper',
					border: '2px solid #000',
					boxShadow: 24,
					p: 3,
					borderRadius: '8px',
					outline: 'none',
				}}
			>
				{/* Header */}
				<Box
					display="flex"
					justifyContent="space-between"
					alignItems="center"
					sx={{ mb: 2 }}
				>
					<Box display="flex" alignItems="center" flexDirection="row">
						<Typography
							id="modal-title"
							variant="h6"
							component="h2"
						>
							Withdraw
						</Typography>
						<Box width={5} />
					</Box>
					<IconButton
						onClick={() => handleClose()}
						sx={{ color: 'inherit' }}
					>
						<CloseIcon />
					</IconButton>
				</Box>

				{/* Amount Deposited */}
				<Box
					display="flex"
					justifyContent="space-between"
					flexDirection="row"
				>
					<Box>Amount deposited</Box>
					<Box>USDC {info.amount}</Box>
				</Box>

				{/* Amount Earned + MUI Tooltip */}
				<Box
					display="flex"
					justifyContent="space-between"
					flexDirection="row"
					sx={{ marginBottom: 3 }}
				>
					<Box
						display="flex"
						alignItems="center"
						justifyContent="center"
					>
						Amount Earned
						<Tooltip
							title={
								<Box>
									<Typography
										variant="body2"
										sx={{ mb: 1, color: '#000' }}
									>
										Amount earned will be sent via a
										separate transaction. TxHash will be
										posted.
									</Typography>
									<Typography
										variant="body2"
										sx={{ mb: 1, color: '#000' }}
									>
										Bridge and gas fees will be deducted
										from the amount earned.
									</Typography>
									<Typography variant="body2">
										<a
											href="https://docs-dudu.rndm.io/how-to-use-dudu-ai-agent-vault"
											target="_blank"
											rel="noopener noreferrer"
											style={{
												color: '#249aff',
												textDecoration: 'underline',
												fontWeight: 900,
											}}
										>
											Docs
										</a>
									</Typography>
								</Box>
							}
							placement="top"
							arrow
							componentsProps={{
								tooltip: {
									sx: {
										padding: 2,
										backgroundColor: '#eae0cc',
										color: '#000',
									},
								},
								arrow: {
									sx: {
										color: '#fff',
									},
								},
							}}
						>
							<InfoIcon
								sx={{
									fontSize: 18,
									cursor: 'pointer',
									ml: 0.5,
								}}
							/>
						</Tooltip>
					</Box>
					<Box>{`USDC ${formatNumber(info.earned)}`}</Box>
				</Box>

				{/* Withdraw Button */}
				<Button
					onClick={() => onClose(true)}
					fullWidth
					variant="contained"
				>
					Withdraw
				</Button>
			</Box>
		</Modal>
	);
};
