// api.ts
import axios, { AxiosResponse } from 'axios';
import { MarketDoc, SelectedMarketData } from 'data';

interface DepositRequest {
	contract: string;
	address: string;
	token?: string;
	amount: string;
	domain?: string;
	eventType: 'withdraw' | 'deposit' | 'claim';
}

interface DepositResponse {
	success: boolean;
	message: string;
	// Add more fields based on your API response structure
}

const apiClient = axios.create({
	baseURL: 'https://apis.vcred.trade', // Replace with your actual API base URL
	headers: {
		'Content-Type': 'application/json',
	},
});

export interface UserDepositParams {
	contract: string;
	address: string;
	token?: string;
	amount?: string;
}

interface UserDepositResponse {
	address: string;
	amount: string;
	contract: string;
	createdAt: string;
	id: string;
	updatedAt: string;
}

export enum TxEventType {
	DEPOSIT = 'deposit',
	WITHDRAW = 'withdraw',
	CLAIM = 'claim',
}

export enum ClaimTimePeriod {
	OneDay = '1d',
	OneMonth = '1m',
	ThreeMonth = '3m',
	SixMonth = '6m',
}

export enum ClaimStatus {
	Processing = 'processing',
	Processed = 'processed',
	Withdrawn = 'withdrawn',
}

export interface UserWithdrawDeposit {
	contract: string;
	address: string;
	amount: string;
	token: string;
	eventType: TxEventType;
	claimTimePeriod?: string;
	claimStatus?: 'processing' | 'processed' | 'withdrawn';
	createdAt?: string;
	domain?: string;
}

export const userDeposit = async (
	depositData: DepositRequest,
): Promise<DepositResponse> => {
	try {
		const response: AxiosResponse<DepositResponse> = await apiClient.post(
			'/api/user/transaction',
			{ ...depositData, domain: window.location.host },
		);
		return response.data;
	} catch (error: any) {
		throw new Error(
			error.response?.data?.message ||
				'An error occurred while processing your request.',
		);
	}
};

export const getUserDeposit = async (
	params: UserDepositParams,
): Promise<any> => {
	try {
		const response = await apiClient.get<{
			lastDeposit: UserDepositResponse[];
		}>('/api/user/last-deposit', { params });
		return response.data;
	} catch (error: any) {
		throw new Error(
			error.response?.data?.message || 'Error fetching user deposit data',
		);
	}
};

export const getUserEarned = async (address: string): Promise<any> => {
	try {
		const response = await apiClient.get<{
			lastDeposit: UserDepositResponse[];
		}>(`/api/user/dudu/total-earning?user_wallet_addrs=${address}`);
		return response.data;
	} catch (error: any) {
		throw new Error(
			error.response?.data?.message || 'Error fetching user deposit data',
		);
	}
};

export const getTotalWallet = async (): Promise<any> => {
	try {
		const response = await apiClient.get<{
			lastDeposit: UserDepositResponse[];
		}>('/api/stats/users-summary');
		return response.data;
	} catch (error: any) {
		throw new Error(
			error.response?.data?.message || 'Error fetching user deposit data',
		);
	}
};

export const userWithdrawTransaction = async (
	payload: UserWithdrawDeposit,
): Promise<any> => {
	try {
		const response = await apiClient.post<{
			lastDeposit: UserDepositResponse[];
		}>('/api/user/transaction', {
			...payload,
			domain: window.location.host,
		});
		return response.data;
	} catch (error: any) {
		throw new Error(
			error.response?.data?.message || 'Error fetching user deposit data',
		);
	}
};

export const getClaimList = async (contract: string, address: string) => {
	try {
		const response: AxiosResponse<UserWithdrawDeposit[]> =
			await apiClient.get(
				`/api/user/claims?address=${address}&contract=${contract}`,
			);
		return response.data;
	} catch (error: any) {
		throw new Error(
			error.response?.data?.message || 'Error fetching user deposit data',
		);
	}
};

export const getMarketInsight = async (marketId: string, address: string) => {
	try {
		const response: AxiosResponse<SelectedMarketData> = await apiClient.get(
			`/api/dudu/data/market/insights?marketId=${marketId}&address=${address}`,
		);
		return response.data;
	} catch (error: any) {
		throw new Error(
			error.response?.data?.message || 'Error fetching user deposit data',
		);
	}
};

export const getMarketList = async () => {
	try {
		const response: AxiosResponse<MarketDoc[]> = await apiClient.get(
			`/api/dudu/data/market-list`,
		);
		return response.data;
	} catch (error: any) {
		throw new Error(
			error.response?.data?.message || 'Error fetching user deposit data',
		);
	}
};

export const requestWithdraw = async () => {
	try {
		const response: AxiosResponse<MarketDoc[]> = await apiClient.get(
			`/api/user/transaction`,
		);
		return response.data;
	} catch (error: any) {
		throw new Error(
			error.response?.data?.message || 'Error fetching user deposit data',
		);
	}
};
