import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import * as React from 'react';
import { useEffect } from 'react';
import * as ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';

import { RainbowKitProvider } from '@rainbow-me/rainbowkit';
import { WagmiProvider } from 'wagmi';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import '@rainbow-me/rainbowkit/styles.css';

import DApp from './DApp';

import theme from 'theme';
import { store } from 'store';
import { wagmiConfig } from 'data/networks';
import WalletConnectModal from 'components/WalletConnectModal';
import '@razorlabs/wallet-kit/style.css';

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement!);

const queryClient = new QueryClient();

const CLARITY_DOMAIN_MAP: any = {
	//'https://agents.rndm.io': 'nucnwd4rka',
	//'https://testnet.rndm.io': 'ocr1f7stnh',
	'https://dudu-dashboard.rndm.io': 'qfnuct99p1',
	'https://cathena-dashboard.rndm.io': 'qfnunn9ktf',
	'https://atlas-move.rndm.io': 'qfoq9y28bp',
};

const Root = () => {
	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment

		const CLARITY_KEY: string = CLARITY_DOMAIN_MAP[window.location.origin];

		if (CLARITY_KEY) {
			const script = document.createElement('script');
			script.innerHTML = `(function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "${CLARITY_KEY}");`;

			document.head.appendChild(script);

			return () => {
				document.head.removeChild(script);
			};
		}
	}, []);

	return (
		<WagmiProvider config={wagmiConfig} reconnectOnMount={true}>
			<QueryClientProvider client={queryClient}>
				<RainbowKitProvider>
					<Provider store={store}>
						<ThemeProvider theme={theme}>
							<CssBaseline />
							<DApp />
							<WalletConnectModal />
						</ThemeProvider>
					</Provider>
					<ToastContainer />
				</RainbowKitProvider>
			</QueryClientProvider>
		</WagmiProvider>
	);
};

root.render(<Root />);
