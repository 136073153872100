export const NAVBAR = [
	/*	{
		id: 0,
		name: 'Docs',
		to: 'https://docs-dudu.rndm.io',
	},*/
	{
		id: 0,
		name: 'How it works',
		to: 'https://docs-dudu.rndm.io/how-to-use-dudu-ai-agent-vault',
		target: '_blank',
	},
	/*{
		id: 1,
		name: 'Insights',
		to: '/markets',
		target: undefined,
	},*/
	/*{
		id: 1,
		name: 'Terms and Conditions',
		to: '/terms-and-condition',
	},*/
	/*{
		id: 1,
		name: 'Positions',
		to: '/positions',
	},*/
];
