import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { ChainNames } from 'config';
import { PortfolioData } from 'pages/Landing/components/TvlGraph';

export type AppState = {
	selectedNetwork: ChainNames;
	isDrawerOpen: boolean;
	loader: {
		loading: boolean;
		message: string;
	};
	isWalletOpen: boolean;
	terms: { isOpen: boolean; isAccepted: boolean };
	chart: {
		frequency: number;
		value: PortfolioData[];
	};
};

const initialState: AppState = {
	selectedNetwork: 'Base',
	isDrawerOpen: false,
	loader: {
		loading: true,
		message: '',
	},
	isWalletOpen: false,
	terms: {
		isOpen: false,
		isAccepted: localStorage.getItem('termsCondition') !== null,
	},
	chart: {
		frequency: 10000,
		value: [],
	},
};

export const appSlice = createSlice({
	name: 'app',
	initialState,
	reducers: {
		setSelectedChain: (
			state: Draft<AppState>,
			action: PayloadAction<ChainNames>,
		) => {
			state.selectedNetwork = action.payload;
		},
		setLoading: (
			state: Draft<AppState>,
			action: PayloadAction<{ loading: boolean; message?: string }>,
		) => {
			state.loader = {
				loading: action.payload.loading,
				message: action.payload.message || 'Please Wait...',
			};
		},
		setAccepted: (
			state: Draft<AppState>,
			action: PayloadAction<{ open: boolean; value: boolean }>,
		) => {
			state.terms = {
				isAccepted: action.payload.value,
				isOpen: action.payload.open,
			};
		},
		openDrawer: (
			state: Draft<AppState>,
			action: PayloadAction<boolean>,
		) => {
			state.isDrawerOpen = action.payload;
		},
		openWallet: (
			state: Draft<AppState>,
			action: PayloadAction<boolean>,
		) => {
			state.isWalletOpen = action.payload;
		},
		setChartFrequency: (
			state: Draft<AppState>,
			action: PayloadAction<number>,
		) => {
			state.chart.frequency = action.payload;
		},
		setChartData: (
			state: Draft<AppState>,
			action: PayloadAction<PortfolioData[]>,
		) => {
			state.chart.value = action.payload;
		},
	},
});

export const appActions = appSlice.actions;
const appReducer = appSlice.reducer;

export default appReducer;
