export const currencyShortDisplay = (amount: number): string => {
	if (amount >= 1e9) {
		return (amount / 1e9).toFixed(2).replace(/\.?0+$/, '') + 'B';
	} else if (amount >= 1e6) {
		return (amount / 1e6).toFixed(2).replace(/\.?0+$/, '') + 'M';
	} else if (amount >= 1e3) {
		return (amount / 1e3).toFixed(2).replace(/\.?0+$/, '') + 'K';
	} else {
		return amount.toFixed(2).replace(/\.?0+$/, '');
	}
};
