import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { SmallItemCard } from 'pages/NewDashboard/components/SmallItemCard';
import { Box, Button, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { useNetwork } from 'hooks/useNetwork';
import { getErc20Contract } from 'utils/contractHelpers';
import { formatUnits, parseUnits } from 'ethers/lib/utils';
import { getDepositContract } from 'utils/depositContractHelpers';
import {
	getClaimList,
	getUserDeposit,
	getUserEarned,
	TxEventType,
	userDeposit,
	UserWithdrawDeposit,
	userWithdrawTransaction,
} from 'apis';
import { adjustDate, checkLock } from 'utils/lockDuration';
import moment from 'moment';
import { useEthersSigner } from 'hooks/useEtherSigner';
import { useAccount } from 'wagmi';
import { appActions } from 'store/app';
import { AmountBox } from 'pages/Landing/components/AmountBox';
import { ClaimModal } from 'pages/Landing/components/ClaimModal';
import { ConfirmationModal } from 'pages/Landing/components/ConfirmationModal';
import { WithdrawModal } from 'pages/Landing/components/WithdrawConfirmation';
import { toast } from 'react-toastify';
import { getErrorMessage } from 'data/errors';
import { DATE_TIME_FORMAT } from 'data/constant';

const Column = styled('div')`
	display: flex;
	flex-direction: column;
`;

const SpaceBetween = styled(Column)`
	height: 100%;
	justify-content: space-between;
`;

const StyledButton = styled(Button)<{ disabled?: boolean }>`
	display: flex;
	padding: 12px 32px;
	box-shadow: 0 4px 4px transparent;
	border-radius: 8px;
	overflow: hidden;
	background-color: ${({ disabled }) => (disabled ? '#dadada' : '#b9886d')};

	:hover {
		background-color: ${({ disabled }) =>
			disabled ? '#dadada' : '#bb805f'};
		cursor: ${({ disabled }) => (disabled ? 'no-drop' : 'pointer')};
		box-shadow: 0 4px 4px transparent;
	}
`;

export const WithdrawPanel: React.ComponentType<{ onSuccess?(): void }> = ({
	onSuccess,
}) => {
	const [earning, setEarning] = useState({
		totalEarning: 0,
	});
	const [isWithdrawOpen, setIsWithdrawOpen] = useState(false);
	const [confirmationOpen, setConfirmationOpen] = useState(false);
	const [confirmationData, setConfirmationData] = useState({});
	const [claims, setClaims] = useState<UserWithdrawDeposit[]>([]);
	const [open, setOpen] = useState(false);
	const [lockedTime, setLockedTime] = useState<string | undefined>(undefined);
	const [loading, setLoading] = useState(false);
	const [balance, setBalance] = useState('0');
	const [contractBal, setContractBal] = useState('0');
	const [amount, setAmount] = useState('');
	const { address: account, isConnected } = useAccount();
	//const account = '0x515E731C795608c52b5Ee559ea62C235bD356785';
	const dispatch = useDispatch();
	const appVault = useSelector((state: RootState) => state.vault);
	const selectedVault = useSelector((state: RootState) => state.vault);
	const selectedAsset = selectedVault.token.find((d) => d.name === 'USDC');
	const selectedContract = selectedVault.exchanger.find(
		(d) => d.value === appVault.exchange,
	);
	const canWithdraw = claims.some((item) => item.claimStatus === 'processed');

	const signer: any = useEthersSigner();
	const bal = parseFloat(parseFloat(balance).toFixed(2)).toLocaleString();
	const contractBalance = parseFloat(
		parseFloat(contractBal).toFixed(2),
	).toLocaleString();
	const isAccountConnected = isConnected;
	const payload = useMemo(() => {
		return {
			contract: selectedContract?.contractAddress || '',
			address: account || '',
		};
	}, [account, selectedContract?.contractAddress]);

	useNetwork();

	const fetchEarned = useCallback(async () => {
		try {
			if (account) {
				const res = await getUserEarned(account);
				if (res.length > 0) {
					setEarning(res[0]);
				}
			} else {
				setEarning({ totalEarning: 0 });
			}
		} catch (e) {
			//
		}
	}, [account]);

	const buttonLabel = useMemo(() => {
		if (!isAccountConnected) return 'Connect Wallet';

		if (loading) return 'Please wait...';

		if (claims.length > 0 && !canWithdraw) {
			const obj = claims[claims.length - 1];
			if (obj.claimTimePeriod === '1m') {
				const formattedDate = moment(
					adjustDate(claims[0].createdAt || ''),
				).format(DATE_TIME_FORMAT);

				return moment(formattedDate).isAfter(moment())
					? 'Withdraw after ' + formattedDate
					: 'Withdraw';
			} else if (obj.claimTimePeriod === '3m') {
				const formattedDate = moment(claims[0].createdAt || '')
					.add(91, 'days')
					.format(DATE_TIME_FORMAT);

				return moment(formattedDate).isAfter(moment())
					? 'Withdraw after ' + formattedDate
					: 'Withdraw';
			} else if (obj.claimTimePeriod === '6m') {
				const formattedDate = moment(claims[0].createdAt || '')
					.add(181, 'days')
					.format(DATE_TIME_FORMAT);

				return moment(formattedDate).isAfter(moment())
					? 'Withdraw after ' + formattedDate
					: 'Withdraw';
			}

			return 'Claim under processing';
		}

		if (lockedTime && checkLock()) {
			return checkLock(canWithdraw);
		}

		return 'Claim';
	}, [canWithdraw, claims, isAccountConnected, loading, lockedTime]);

	const fetchBalance = useCallback(async () => {
		try {
			if (account) {
				const assetContract = getErc20Contract(
					selectedAsset?.address || '',
					signer?.provider.getSigner(account).connectUnchecked(),
				);
				const assetBalance = await assetContract.balanceOf(account);
				setBalance(
					formatUnits(assetBalance, selectedAsset?.decimalValue),
				);
			} else {
				setBalance('0');
			}
		} catch (e) {
			//
		}
	}, [account, signer, selectedAsset?.address, selectedAsset?.decimalValue]);

	const fetchClaims = useCallback(async () => {
		try {
			if (account) {
				const res = await getClaimList(
					selectedContract?.contractAddress || '',
					account,
				);

				setClaims(res);
			}
		} catch (e) {
			//
		}
	}, [account, selectedContract?.contractAddress]);

	const fetchContractBalance = useCallback(async () => {
		try {
			if (account) {
				dispatch(
					appActions.setLoading({
						loading: true,
						message: 'Please Wait...',
					}),
				);
				const contract = getDepositContract(
					selectedContract?.contractAddress || '',
					signer?.provider.getSigner(account).connectUnchecked(),
				);

				// fetch balance
				const userContractBalance = await contract.balanceOf(account);
				const userBal = await contract.convertToAssets(
					userContractBalance,
				);
				setContractBal(
					formatUnits(userBal, selectedAsset?.decimalValue) || '0',
				);
				setAmount(
					formatUnits(userBal, selectedAsset?.decimalValue) || '0',
				);
			} else {
				setContractBal('0');
			}
		} catch (e) {
			//
		} finally {
			dispatch(appActions.setLoading({ loading: false, message: '' }));
		}
	}, [
		account,
		selectedContract?.contractAddress,
		signer?.provider,
		selectedAsset?.decimalValue,
		dispatch,
	]);

	const withdrawTransaction = async () => {
		try {
			await userWithdrawTransaction(
				confirmationData as UserWithdrawDeposit,
			);

			await fetchClaims();
			setConfirmationOpen(false);
			setOpen(false);
		} catch (e) {
			//
		}
	};

	const onWithDraw = async () => {
		try {
			await userWithdrawTransaction({
				contract: selectedContract?.contractAddress || '',
				address: account || '',
				amount: contractBalance,
				token: 'USDC',
				eventType: TxEventType.WITHDRAW,
				//claimTimePeriod: period,
			});
			await fetchClaims();
			setIsWithdrawOpen(false);
		} catch (e) {
			//
		}
	};

	const withdraw = async () => {
		try {
			setLoading(true);

			const res = await getUserDeposit(payload);
			if (res.lastDeposit.length > 0) {
				setLockedTime(res.lastDeposit[0].createdAt);

				const contract = getDepositContract(
					selectedContract?.contractAddress || '',
					signer?.provider.getSigner(account).connectUnchecked(),
				);

				const tx = await contract.withdraw(
					parseUnits(amount, selectedAsset?.decimalValue),
					account,
					account,
				);
				await tx.wait();
				await userDeposit({
					contract: selectedContract?.contractAddress || '',
					address: account || '',
					amount,
					token: 'USDC',
					eventType: 'withdraw',
				});
				toast(
					<Box>
						<Typography variant="h6" sx={{ color: '#000', mb: 1 }}>
							Successfully withdrawn
						</Typography>
						<Typography
							variant="body2"
							sx={{ mb: 1, color: '#000' }}
						>
							Amount earned will be sent via a seperate
							Transaction within 24 hrs
						</Typography>
						<Typography
							variant="body2"
							sx={{ mb: 1, color: '#000' }}
						>
							Bridge and gas fees will be deducted from the amount
							earned.
						</Typography>
						<Typography variant="body2">
							<a
								href="https://docs-dudu.rndm.io/how-to-use-dudu-ai-agent-vault"
								target="_blank"
								rel="noopener noreferrer"
								style={{
									color: '#249aff',
									textDecoration: 'underline',
									fontWeight: 900,
								}}
							>
								Docs
							</a>
						</Typography>
					</Box>,
					{
						type: 'success',
						autoClose: false,
						icon: false,
						style: {
							backgroundColor: '#eae0cc',
						},
					},
				);
				setAmount('0');
				onWithDraw();
				onSuccess?.();
				return;
			}
		} catch (e: any) {
			toast(
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						gap: '0.75rem',
					}}
				>
					<div>
						{getErrorMessage(e?.error?.code || e?.code, e) ||
							'Something went wrong'}
					</div>
					{getErrorMessage(e?.error?.code || e?.code, e).includes(
						'Something went wrong',
					) && (
						<StyledButton
							onClick={() => {
								window.open(
									'https://t.me/+96H-v4meO1szMmJk',
									'_blank',
								);
							}}
							style={{ height: 'min-content', padding: 5 }}
						>
							<Typography
								variant="caption"
								fontWeight="bold"
								py="6px"
								color="#fff"
								style={{ lineHeight: '120%' }}
							>
								Support
							</Typography>
						</StyledButton>
					)}
				</div>,
				{
					type: 'error',
				},
			);
		} finally {
			fetchContractBalance();
			fetchBalance();
			setLoading(false);
		}
	};

	const fetchLockedTime = useCallback(async () => {
		const res = await getUserDeposit(payload);
		if (res.lastDeposit.length > 0) {
			setLockedTime(res.lastDeposit[0].createdAt);
		}
	}, [payload]);

	useEffect(() => {
		fetchContractBalance();
		fetchBalance();
		fetchLockedTime();
	}, [fetchBalance, fetchContractBalance, fetchLockedTime]);

	useEffect(() => {
		fetchClaims();
	}, [fetchClaims]);

	useEffect(() => {
		fetchEarned();
	}, [fetchEarned]);

	return (
		<>
			<SpaceBetween>
				<Column>
					<SmallItemCard />
					<Box height={16} />
					<AmountBox
						currency={{
							icon: selectedVault.token[0].icon,
							name: selectedVault.token[0].name,
						}}
						text={amount}
						leftTxt={`${selectedVault.currency} ${bal}`}
						rightTxt={
							selectedVault.currency + ' ' + contractBalance
						}
					/>

					<Box height={20} />
				</Column>
				<Column>
					<StyledButton
						variant={'contained'}
						onClick={() => {
							if (canWithdraw) {
								setIsWithdrawOpen(true);
							} else {
								setOpen(true);
							}
						}}
						disabled={
							loading ||
							parseFloat(amount || '0') === 0 ||
							contractBal < amount ||
							(claims.length === 0 ? false : !canWithdraw)
						}
					>
						<Typography fontWeight={600} color={'#fff'}>
							{buttonLabel}
						</Typography>
					</StyledButton>
					<Box height={32} />
				</Column>
			</SpaceBetween>
			<ConfirmationModal
				open={confirmationOpen}
				onClose={() => setConfirmationOpen(false)}
				onConfirm={() => {
					if (parseFloat(contractBalance) > 0) {
						withdrawTransaction();
					}
				}}
				info={confirmationData as UserWithdrawDeposit}
			/>
			<WithdrawModal
				open={isWithdrawOpen}
				onClose={(success) => {
					if (success) {
						withdraw();
					}
					setIsWithdrawOpen(false);
				}}
				info={{
					earned: earning.totalEarning,
					amount: contractBalance,
				}}
			/>
			<ClaimModal
				open={open}
				setClose={(period) => {
					if (period) {
						setConfirmationOpen(true);
						setConfirmationData({
							contract: selectedContract?.contractAddress || '',
							address: account || '',
							amount: contractBalance,
							token: 'USDC',
							eventType: 'claim',
							claimTimePeriod: period,
						});
					} else {
						setOpen(false);
					}
				}}
			/>
		</>
	);
};
