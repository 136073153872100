import React, { useEffect, useMemo, useState } from 'react';
import { Vault } from 'data/Vaults';
import styled from '@emotion/styled';
import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { formatNumber, formatNumberToCurrencyView } from 'utils/formatCurrency';
import { ParseFloat } from 'utils/parseFloat';
import moment from 'moment/moment';
import { FREQUENCY } from 'data/frequency';
import { calcMaxdrawdown } from '../../../utils';

const Card = styled('div', {
	shouldForwardProp: (prop) => prop !== 'disabled',
})<{ disabled: boolean }>`
	border-radius: 8px;
	width: 100%;
	overflow: hidden;

	:hover {
		cursor: ${(props) => (!props.disabled ? 'pointer' : 'no-drop')};
	}
`;

const Row = styled('div')`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

const Header = styled(Row)`
	width: 100%;
	background: #eae0cc;
	padding: 8px;
	justify-content: space-between;
`;

const Body = styled('div')`
	padding: 10px 8px;
	background: #e6e6e966;
`;

const Item = styled(Row)`
	background: #eae4d9;
	justify-content: space-between;
	padding: 6px 10px;
	border-radius: 4px;
`;

export const ItemCard: React.ComponentType<
	Vault & {
		stat: {
			interest_value: number;
			pnl: string;
			portfolio_value: string;
			volume_trade: string;
		};
	}
> = ({ stat, ...props }) => {
	const [generalStats, setGeneralStats] = useState<any>([]);
	const selectedVault = useSelector((state: RootState) => state.vault);
	const appState = useSelector((state: RootState) => state.app);
	const chartData = appState.chart.value;
	const targetDate = moment
		.utc()
		.endOf('day')
		.subtract(appState.chart.frequency, 'days');

	const value = useMemo(() => {
		let r = 0;
		for (let i = 0; i < chartData.length - 1; i++) {
			if (moment.utc(chartData[i].createdAt).isAfter(targetDate)) {
				r = i === 0 ? 0 : chartData[i - 1]?.pnl;
				break;
			}
		}
		return r;
	}, [chartData, targetDate]);

	const formattedData = appState.chart.value
		.map((d) => {
			return { ...d, pnl: d.pnl - value };
		})
		.slice(-appState.chart.frequency);

	useEffect(() => {
		async function fetchGeneralStats() {
			const stats: any = await calcMaxdrawdown();
			setGeneralStats(stats);
		}

		fetchGeneralStats().catch(console.log);
	}, []);

	const drawdown = generalStats?.[0]?.drawdown
		? `${(generalStats?.[0]?.drawdown * 100).toFixed(2)}%`
		: '';

	return (
		<Card
			style={{
				opacity: props.isActive ? 1 : 0.5,
				border: `2px solid ${
					props.name === selectedVault.name
						? '#b9886d'
						: 'transparent'
				}`,
			}}
			onClick={() => {
				if (props.isActive) {
					//
				}
			}}
			disabled={!props.isActive}
		>
			<Header>
				<Row>
					<img
						src={props.logo}
						alt={props.name}
						width={25}
						height={25}
					/>
					<Box width={8} />
					<Typography variant={'subtitle1'}>{props.name}</Typography>
				</Row>
				{!props.isActive && (
					<Row>
						<Typography variant={'subtitle1'} color={'#235ee1'}>
							Soon
						</Typography>
					</Row>
				)}
			</Header>
			<Body>
				<Typography variant={'subtitle1'}>
					{props.shortDescription}
				</Typography>
				<Box height={10} />
				<Typography variant={'subtitle1'} fontWeight={500}>
					Performance (
					{
						FREQUENCY.find(
							(d) => appState.chart.frequency === d.value,
						)?.name
					}
					)
				</Typography>
				<Box height={3} />
				<Item>
					<Typography variant={'subtitle1'}>
						DUDU Points (Coming soon)
					</Typography>
				</Item>
				{/*<Item>
					<Typography variant={'subtitle1'}>TVL</Typography>
					<Typography variant={'subtitle1'}>
						${Number(totalTvl).toLocaleString()}
					</Typography>
				</Item>*/}
				<Item>
					<Typography variant={'subtitle1'}>Overall PNL</Typography>
					<Typography variant={'subtitle1'}>
						{formattedData.length > 0
							? formatNumber(
									formattedData[
										formattedData.length - 1
									].pnl.toFixed(2),
							  )
							: '-'}
					</Typography>
				</Item>
				<Item>
					<Typography variant={'subtitle1'}>Volume traded</Typography>
					<Typography variant={'subtitle1'}>
						$
						{formatNumberToCurrencyView(
							ParseFloat(stat.volume_trade || '0'),
						)}
					</Typography>
				</Item>

				{appState.chart.frequency == 7 && (
					<Item>
						<Typography variant={'subtitle1'}>
							Max drawdown
						</Typography>
						<Typography variant={'subtitle1'}>
							3.5%{/*drawdown*/}
						</Typography>
					</Item>
				)}
				<Box height={10} />
			</Body>
		</Card>
	);
};
